import { Steps } from "antd";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AccountApi from "../../Api/AccountApi";
import TemplateApi from "../../Api/TemplatesApi";
import Button from "../../components/Button/Button";
import ContactsPreview from "../../components/CreateContacts/ContactsPreview/ContactsPreview";
import HeaderMap from "../../components/CreateContacts/HeaderMap/HeaderMap";
import SelectAccount from "../../components/CreateContacts/SelectAccount/SelectAccount";
import Summary from "../../components/CreateContacts/Summary/Summary";
import UploadFile from "../../components/CreateContacts/UploadFile/UploadFile";
import ModalConfirmation from "../../components/ModalConfirmation/ModalConfirmation";
import useAuth from "../../Hooks/useAuth";
import useSpinner from "../../Hooks/useSpinner";
import contactsReducer, { initialContactsState } from "../../reducers/contactsReducer";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import { filterdAccountsByUser, notificationError, notificationOk } from "../../utils/helpers";
import styles from "./Contacts.module.css";
import * as XLSX from 'xlsx';
import useSteper from "../../Hooks/useSteper";

const { Step } = Steps;

const Contacts = () => {
  const [tr] = useTranslation("create-contacts-new");
  const [t] = useTranslation("create-contacts");
  const { auth } =  useAuth();

  const [steps, setSteps] = useState([]);
  const [modalContactsVisible, setModalContacsVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [validationsListGroupName, setValidationsListGroupName] = useState(false);

  const [contactsState, contactsDispacth] = useReducer(contactsReducer, initialContactsState);

  const { GetAccountsByCompanyId } = AccountApi();
  const { addContacts } = TemplateApi();
  const { spinnerDispacth } = useSpinner();
  const navigate = useNavigate();  
  const { breadcrumbDispacth} = useSteper();
  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
    });

    GetAccountsByCompanyId(auth.companyId, auth.accountAccessToken)
    .then(response => {

      let accountByUser =  filterdAccountsByUser(response, auth.userAccounts, auth.role);

      let accountMap = accountByUser.map(x => { return { label: x.name, value: x.accountId } })        
      setAccounts(accountMap);

      setSteps([
        {
          title: t("steper.one")
        },
        {
          title: t("steper.two")
        },
        {
          title: t("steper.three")
        }
      ]);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
      });

    })
    .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBYCOMPANY }
      });
    })
  }, [t])

  const renderLeftCard = () => {
    switch (current) {
      case 0:
        return <SelectAccount
          accounts={accounts}
          defaultListGroupName={contactsState.listGroupName}
          defaultAccountsSelected={contactsState.accountsSelected.map(x => { return x.value })}
          onAccountChange={onAccountChange}
          onListGroupNameChange={onListGroupNameChange}
          showValidations={validationsListGroupName}
        />
      case 1:
        return <HeaderMap
          headers={contactsState.fileHeaders}
          defaultHeaderSelected={contactsState.headersMap.selectedHeaders ? contactsState.headersMap.selectedHeaders : null}
          defaultChecked={contactsState.headersMap.customFields}
          defaultPhoneFieldName={contactsState.headersMap.mobileNumberColumn}
          defaultWildcardSelected={contactsState.headersMap.customFields}
          onChangeHeaders={handleChangeHeaders}
          onChangeMobileNumberField={handleMobileNumberField}
          onChangeCustomFields={handleChangeCustomFields}
        />
      case 2:
        return <ContactsPreview 
          headers={contactsState.headersMap.selectedHeaders}
          fileData={contactsState.fileData}
          mobileNumberColumn={contactsState.headersMap.mobileNumberColumn}
        />
    }
  }

  const renderRightCard = () => {
    switch (current) {
      case 0:
        return <UploadFile
          onUploadFile={handleUploadFile}
          onRemoveFile={handleRemoveFile}
          defaultFile={contactsState.file}
        />;
      case 1:
        return <ContactsPreview 
          headers={contactsState.headersMap.selectedHeaders}
          fileData={contactsState.fileData}
          mobileNumberColumn={contactsState.headersMap.mobileNumberColumn}
        />
      case 2:
        return <Summary
          groupName={contactsState.listGroupName}
          totalContacts={contactsState.fileRows}
          totalWildcards={contactsState.headersMap.customFields.length}
          mobileNumberColumn={contactsState.headersMap.mobileNumberColumn}
        />
    }
  }

  const nextStep = () => {

    switch (current) {
      case 0:
        stepOne();
        break;
      case 1:
        stepTwo();
        break;
      case 3:
        stepThree();
        break;
      default:
        console.log('deafult step case')
        break;
    }

  }

  const prevStep = () => {
    let prevStepValue = current - 1;
    let valueName = "";  
    // Determina el nombre del paso anterior
    switch (prevStepValue) {
      case 0:
        valueName = tr("create.create-step-one");
        break;
      case 1:
        valueName = tr("create.create-map");
        break;
      case 2:
        valueName = tr("create.create-confirm");
        break;
      default:
        break;
    }
    // Activa la acción del breadcrumb con el nombre del paso anterior
    breadcrumbDispacth({
      type: TYPES.BREADCRUMB.SELECT_STEP,
      payload: valueName
    });  
    // Actualizar el estado local con el valor del paso anterior
    setCurrent(prevStepValue);

    switch (current) {
      case 1:
        setCurrent(current - 1);
        break;
      case 2: 
        setCurrent(current - 1);
        break;
      default:
        console.log('deafult step case')
        break;
    }
  }

  const stepOne = () => {
    if (contactsState.listGroupName === "") {
      setValidationsListGroupName(true);
      return;
    }

    if (contactsState.accountsSelected.length === 0) {
      notificationError(t("account.alert-mandatory-accounts"));
      return;
    }

    if (!contactsState.file) {
      notificationError(t("upload-file.alert-mandatory-file"));
      return;
    }
    
    changeStep();
  };

  const stepTwo = () => {
    if (!contactsState.headersMap.mobileNumberColumn) {
      notificationError(t("map-header.alert-mobile-number"));
      return;
    }
    
    changeStep();
  };

  const stepThree = () => {

  }

  const changeStep = () => {
    let nextStepValue = current + 1;
    let valueName = "";
    if(nextStepValue == 0){
      valueName = (tr("create.create-step-one"))
    }
    if(nextStepValue == 1){
      valueName = (tr("create.create-map"))
    }
    if(nextStepValue == 2){
      valueName = (tr("create.create-confirm"))
    }   
    breadcrumbDispacth({
      type: TYPES.BREADCRUMB.SELECT_STEP,
      payload: valueName
    });
    //changeStepfromBreadCrumb(nextStepValue);
    setCurrent(nextStepValue);
  }

  const onAccountChange = (value) => {
    let accountsList = value.map(x => {
      let result = accounts.find(a => a.value == x);
      return result;
    });

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_ACCOUNTS,
      payload: accountsList
    });
  }

  const onListGroupNameChange = (value) => {
    contactsDispacth({
      type: TYPES.CONTACTS.ADD_LISTGROUP_NAME,
      payload: value
    });
  }

  const handleUploadFile = (file, fileType) => {
    if (file) {
      contactsDispacth({
        type: TYPES.CONTACTS.ADD_FILE,
        payload: file
      })
        contactsDispacth({
            type: TYPES.CONTACTS.ADD_SELECTEDHEADERS,
            payload: []
        });

        contactsDispacth({
            type: TYPES.CONTACTS.ADD_MOBILENUMBERCOLUMN,
            payload: null
        });

        contactsDispacth({
            type: TYPES.CONTACTS.ADD_CUSTOMEFIELDS,
            payload: []
        });
      
      switch (fileType) {
        case CONSTANTS.FILETYPES.CSV.NAME:
          processCsvFile(file);
          break;
        case CONSTANTS.FILETYPES.EXCEL.NAME:
          processExcelFile(file);
          break;      
        default:
          break;
      }
    }
  };

  const processCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = onLoadCsvFileInMemory;
    reader.readAsText(file);
  }

  const processExcelFile = (file) => {
    let f = file
    const reader = new FileReader();
    reader.onload = onLoadExcelFileInMemory;
    reader.readAsBinaryString(f);
  }

  const onLoadCsvFileInMemory = event => {
    var csv = event.target.result;
    fileProcess(csv);
  }

  const onLoadExcelFileInMemory = event => {
    /* Parse data */
    const bstr = event.target.result;
    const wb = XLSX.read(bstr, {type:'binary'});
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const csv = XLSX.utils.sheet_to_csv(ws, {header:1});
    fileProcess(csv);
  }

  const fileProcess = (csv) => {
    var rows = csv.split('\n').map(row => row.trim()).filter(row => row.length > 0);

    if (rows.length < 2) {
      notificationError(t("upload-file.alert-file-rows-validation"));
      handleRemoveFile();
      return;
    }

    let separator = rows[0].includes(',') ? ',' : ';'

    var cols = rows[0].split(separator);
    let headers = [];

    if(cols.length > 7) {
      notificationError(t("upload-file.alert-file-columns-validation"));
      handleRemoveFile();
      return;
    }

    for (let j = 0; j < cols.length; j++) {
      headers.push({
        name: cols[j].replace(/(\r\n|\n|\r)/gm, "").trim(),
        column: j
      });
    }

    let data = [];
    let count = rows.length > 10 ? 10 : rows.length

    for (let i = 1; i < count; i++) {

      cols = rows[i].split(separator);
      let row = [];

      if (cols.length !== headers.length) {
        notificationError(t("upload-file.alert-file-rows-columns-validation"));
        handleRemoveFile();
        return;
      }

      for (let j = 0; j < cols.length; j++) {
        row.push({
          value: cols[j].replace(/(\r\n|\n|\r)/gm, ""),
          column: j
        });
      }

      data.push({
        value: row,
        row: i
      });

    }

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILEDATA,
      payload: data
    })

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILEHEADERS,
      payload: headers
    })

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILEROWS,
      payload: rows.length - 1
    })
  }

  const handleRemoveFile = () => {
    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILE,
      payload: null
    })

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILEHEADERS,
      payload: []
    })

    contactsDispacth({
      type: TYPES.CONTACTS.ADD_FILEROWS,
      payload: 0
    })
  };

  const handleChangeHeaders = (headerData, isPhoneNumber) => {
    let headersMap = [...contactsState.headersMap.selectedHeaders];

    if(isPhoneNumber) {
      contactsDispacth({
        type: TYPES.CONTACTS.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    } else {
      let phoneHeader = headersMap.find(x => x.name == contactsState.headersMap.mobileNumberColumn);
      headerData.unshift(phoneHeader);
      contactsDispacth({
        type: TYPES.CONTACTS.ADD_SELECTEDHEADERS,
        payload: headerData
      })
    }
  };

  const handleMobileNumberField = (value) => {
    contactsDispacth({
      type: TYPES.CONTACTS.ADD_MOBILENUMBERCOLUMN,
      payload: value
    })
  };

  const handleChangeCustomFields = (value) => {
    contactsDispacth({
      type: TYPES.CONTACTS.ADD_CUSTOMEFIELDS,
      payload: value
    })
  };

  const handleConfirmModal = () => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.CONTACTSSUMMARY }
    }); 

    const formData = new FormData();
    formData.append('files[]', contactsState.file);

    const json = JSON.stringify({ MobileNumberColumn: contactsState.headersMap.mobileNumberColumn,
      CustomFields: contactsState.headersMap.customFields
    });

    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("data", blob);

    let contacts = {
      GroupName: contactsState.listGroupName,
      Accounts: contactsState.accountsSelected.map(x => x.value),
      CompanyId: auth.companyId
    }

    const blobContacts = new Blob([JSON.stringify(contacts)], {
      type: 'application/json'
    });

    formData.append("contacts", blobContacts);

    addContacts(formData)
    .then(() => {      
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSSUMMARY }
      });
      notificationOk(t("summary.notification-contacts-ok"));
      navigate("/Contacts/History");
    })
    .catch(error => {
      console.error(error);
      notificationError(t("summary.notification-contacts-error") + ' ' + error.message);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CONTACTSSUMMARY }
      });
      setModalContacsVisible(false);
    })
  }

  return (
    <>
      <div className="yp-workspace">
        <div className={styles['step-header']}>
          <Steps labelPlacement="horizontal" current={current} direction="horizontal">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className={styles['step-content']}>
          <div className={styles['step-content-card']}>
            {
              renderLeftCard()
            }
          </div>
          <div className={styles['step-content-card']}>
            {
              renderRightCard()
            }
          </div>
        </div>
      </div>
      <div className={styles['step-buttons-container']}>
        <div className={styles['step-buttons']}>
          {current < steps.length && current > 0 && (
            <Button style={{ width: 150, marginRight: '10px' }} type="secondary" onClick={() => navigate(0)}>
              {t("actions-buttons.cancel")}
            </Button>
          )}
          {current > 0 && current < steps.length  && (
            <Button style={{ width: 150, marginRight: '10px' }} type="optional" onClick={() => prevStep()}>
              {t("actions-buttons.previous")}
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => setModalContacsVisible(true)}>
              {t("actions-buttons.done")}
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button style={{ width: 150 }} type="primary" onClick={() => nextStep()}>
              {t("actions-buttons.next")}
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmation
        open={modalContactsVisible}
        title={t("modal-generate-contacts.title")}
        onCancel={() => setModalContacsVisible(false)}
        onOk={handleConfirmModal}
        okBtnText={t("modal-generate-contacts.btn-ok")}
      >
        <p>{t("modal-generate-contacts.content")}</p>
      </ModalConfirmation>
    </>
  );
}
 
export default Contacts;