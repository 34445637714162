import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { Icons } from '../../../../utils/icons';
import { useTranslation } from 'react-i18next';
import "../Create/Create.css"

const { Text } = Typography;

const MobilePreview = ({ previewValues }) => {
  const [t] = useTranslation("whatsapp-templates");
  const [previewMessageBody, setPreviewMessageBody] = useState(null)

  useEffect(() => {
    if (previewValues) {
      let transformedMessage = previewValues?.bodyText || "";
      previewValues?.bodyExample?.forEach((tag, index) => {
        transformedMessage = transformedMessage.replace(`{{${index + 1}}}`, tag ? `<u>${tag}</u>` : `{{${index + 1}}}`);
      });
      setPreviewMessageBody(transformedMessage);
    }
  }, [previewValues]);

  return (
    <div className="device-mockup">
      <div className="device-frame">
        <div className="notch"></div>
        <div className="device-header">
          <div className='contact'>
            <div className='person-avatar' />
            <Text strong>{t("preview.contact")}</Text>
          </div>
          <div className="header-icons">
            <Icons.PhoneOutlined />
          </div>
        </div>
        <div className="chat-preview" /*bordered={false}*/>
          <div className="message-card" /*bordered={false}*/>
            <Text strong>{previewValues?.headerText}</Text>
            <div className="chat-bubble">
              <div className="inner-message-card" /*bordered={false}*/>
                <Text>{previewMessageBody ? <span dangerouslySetInnerHTML={{ __html: previewMessageBody || "" }}></span> : t("preview.message")}</Text>
                <div className='message-footer'>
                  <div className="footer-text">{previewValues?.footerText}</div>
                </div>
              </div>
              <div className="bubble-arrow"></div>
            </div>
          </div>
          <div className="button-group">
            {previewValues?.buttons?.map((button, index) => {
              if (button.type === "QUICK_REPLY") {
                return (
                  <div key={index} className="reply-button">
                    {button.text}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="url-group">
            {previewValues?.buttons?.map((button, index) => {
              if (button.type === "URL") {
                return (
                  <div key={index} className="url-button">
                    <a href={button.url} target="_blank" rel="noopener noreferrer">
                      {button.text}
                    </a>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="chat-input">
          <input type="text" disabled placeholder={t("preview.input")} />
        </div>
      </div>
    </div>
  );
};

export default MobilePreview;
