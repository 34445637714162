import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import {
  UserOutlined,
  HomeOutlined,
  SettingOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  WarningFilled,
  StarFilled,
  AuditOutlined, 
  ShopOutlined,
  PhoneOutlined,
  EditOutlined, 
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  faUserRobot,
  faUserTag,
  faUser,
  faClipboardListCheck, 
  faStore, 
  faFileChartLine,  
} from '@fortawesome/pro-solid-svg-icons';
import {
  faClipboardList, 
  faShoppingBag, 
  faListUl, 
  faChartSimple, 
  faBuilding, 
  faAddressBook, 
  faFolderTree, 
  faUsers, 
  faUserPlus, 
  faList, 
  faMessage, 
  faUserGroup, 
  faCopy, 
  faLink, 
  faCode, 
  faFile, 
  faVoicemail, 
  faHammer, 
  faCommentDots
  } from '@fortawesome/free-solid-svg-icons';

export const Icons = {

  CheckCircleOutlined,
  WarningFilled,
  ArrowLeftOutlined, 
  ArrowRightOutlined,
  UserOutlined,
  HomeOutlined,
  SettingOutlined,
  StarFilled,
  AuditOutlined, 
  ShopOutlined,
  PhoneOutlined,
  EditOutlined, 
  DeleteOutlined,
  PlusOutlined,
  UserRobot: faUserRobot,
  UserTag: faUserTag,
  User: faUser,
  ClipboardList: faClipboardList,
  ShoppingBag: faShoppingBag,
  ListUl: faListUl,
  ChartSimple: faChartSimple,
  Building: faBuilding,
  AddressBook: faAddressBook,
  FolderTree: faFolderTree,
  Users: faUsers,
  UserPlus: faUserPlus,
  List: faList,
  Message: faMessage,
  UserGroup: faUserGroup,
  Copy: faCopy,
  Link: faLink,
  Code: faCode,
  File: faFile,
  Voicemail: faVoicemail,
  Hammer: faHammer,
  CommentDots: faCommentDots,
  ClipboardListCheck: faClipboardListCheck,
  Store: faStore,
  FileChartLine: faFileChartLine,  
  WhatsappSquare: faWhatsappSquare,
};

export { FontAwesomeIcon };