import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Checkbox, Form, Input, Select, Row, Col, Typography, Card } from 'antd';
import { Icons } from '../../../../utils/icons';
import { useTranslation } from 'react-i18next';
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import HelperButton from "../../../../components/HelperButton/HelperButton";
import styles from "./Edit.module.css";
import { AuthContext } from '../../../../contexts/authRedirect';
import WhatsappApi from '../../../../Api/WhatsappApi';
import { cleanErrorMessage, notificationError, notificationOk } from '../../../../utils/helpers';
import useSpinner from '../../../../Hooks/useSpinner';
import CONSTANTS from "../../../../utils/const";
import TYPES from '../../../../reducers/types';

import "./Edit.css"
import { useNavigate } from 'react-router-dom';
import MessageWildcard from '../../../../components/MessageWildcard/MessageWildcard';

const { Option } = Select;
const { Text, Title } = Typography;

const EditWhatsAppTemplate = ({ idToEdit, setIdToEdit }) => {
  const [form] = Form.useForm();
  const [t] = useTranslation("whatsapp-templates");
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [selectedActions, setSelectedActions] = useState([])
  const [buttons, setButtons] = useState([])
  const [quickReplies, setQuickReplies] = useState([]);
  const [urlInputs, setUrlInputs] = useState({ url: '', text: '' });
  const [formValues, setFormValues] = useState({})
  const [messageBody, setMessageBody] = useState("")
  const [previewMessageBody, setPreviewMessageBody] = useState("")

  const { spinnerDispacth } = useSpinner();
  const { auth } = useContext(AuthContext);
  const [wcList, setwcList] = useState([])
  const { createUpdateTemplate, getTemplateById } = WhatsappApi();
  const navigate = useNavigate(); 
  const ref = useRef();

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
    });
    getInitialTemplateData()
      .then(() => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
        });
      })
  }, []);

  const getInitialTemplateData = async () => {
    try {
      const response = await getTemplateById(idToEdit);
      setTemplateToEdit(response.data)
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (templateToEdit?.buttons && templateToEdit?.buttons.length > 0) {
      const initialSelectedActions = [...new Set(templateToEdit.buttons.map(button => button.type))];
      setSelectedActions(initialSelectedActions);
      setButtons(templateToEdit.buttons);
    }

    if (templateToEdit?.bodyExample?.length > 0) {
      setwcList(templateToEdit?.bodyExample?.map((_v) => ({ value: _v, label: _v })))
    }

    if (templateToEdit?.bodyText) {
      setPreviewMessageBody(cleanMessage(replacePlaceholders(templateToEdit?.bodyText, templateToEdit?.bodyExample)));
      setMessageBody(replacePlaceholders(templateToEdit?.bodyText, templateToEdit?.bodyExample))
    }
  }, [templateToEdit]);

  const handleCheckboxChange = (action) => {
    let updatedActions;
    if (selectedActions.includes(action)) {
      updatedActions = selectedActions.filter(act => act !== action);
      if (action === "QUICK_REPLY") {
        setButtons(buttons.filter(button => button.type !== "QUICK_REPLY"));
        setQuickReplies([]);
      } else if (action === "URL") {
        form.validateFields();
        setButtons(buttons.filter(button => button.type !== "URL"));
        form.resetFields(['url', 'urltext']);
        setUrlInputs({ url: '', text: '' });
      }
    } else {
      updatedActions = [...selectedActions, action];
      if (action === "QUICK_REPLY") {
        setQuickReplies([t("options.yes"), t("options.no")]);
        handleQuickReplyChange([t("options.yes"), t("options.no")]);
      }
    }
    setSelectedActions(updatedActions);
  };

  const handleQuickReplyChange = (values) => {
    if (values.length === 0) {
      setSelectedActions(selectedActions.filter((act) => act !== "QUICK_REPLY"));
    }
    
    const quickReplies = values.map((text) => ({ type: "QUICK_REPLY", text }));
    setButtons([
      ...buttons.filter((button) => button.type !== "QUICK_REPLY"),
      ...quickReplies,
    ]);
    setQuickReplies(values);
  };

  const handleURLChange = (e, field) => {
    const newUrlInputs = { ...urlInputs, [field]: e.target.value };
    setUrlInputs(newUrlInputs);
    const urlButton = { type: "URL", url: newUrlInputs.url, text: newUrlInputs.text };
    setButtons([
      ...buttons.filter((button) => button.type !== "URL"),
      urlButton,
    ]);
  };

  useEffect(() => {
    form.resetFields()
    setFormValues({ headerText: templateToEdit?.headerText, footerText: templateToEdit?.footerText })
  }, [templateToEdit]);

  useEffect(() => {
    setPreviewMessageBody(cleanMessage(replacePlaceholders(messageBody, wcList)));
    setMessageBody(prev => replacePlaceholders(updateText(wcList, prev), wcList))
  }, [wcList, messageBody]);

  const updateText = (structure, text) => {
    if (!structure || !text) return
    const valuesInStructure = structure.map(item => item.value);
    const updatedText = text.replace(/@#(.*?)@#/g, (match, p1) => {
      return valuesInStructure.includes(p1) ? match : '';
    });
  
    return updatedText;
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((v) => {
        const payload = {
          wabaTemplateId: idToEdit,
          lastModifiedBy: auth.userName,
          category: v.category,
          headerText: v.headerText,
          bodyText: v.bodyText,
          bodyExample: v.bodyExample,
          footerText: v.footerText,
          buttons: buttons
        }
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATEWABA }
        });
        createUpdateTemplate(payload, "PUT")
          .then((v) => {
            notificationOk(t("notification-ok-edit"));
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
            });
            setIdToEdit(null)
          })
          .catch(e => {
            notificationError(cleanErrorMessage(e) || t("error-editing-template"), 15)
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEWABA }
            });
          })
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleChangeMessage = value => {
    const _message = replaceWithOrder(value?.message)
    if(value.message) {
      setPreviewMessageBody(cleanMessage(value.message));
      setMessageBody(value.message)
      form.setFieldsValue({ bodyText: _message })
    } else {
      setPreviewMessageBody("");
      form.setFieldsValue({ bodyText: "" })
    }
  };

  const replaceWithOrder = (inputString) => {
    if (!inputString) return
    const regex = /@#(.*?)@#/g;
    const occurrences = {};
    let counter = 1;
  
    const resultString = inputString.replace(regex, (_, match) => {
      if (!(match in occurrences)) {
        occurrences[match] = counter++;
      }
      return `{{${occurrences[match]}}}`;
    });
  
    return resultString;
  };

  const cleanMessage = (message) => {
    return (message || "").replace(/@#/g, "")
  }

  function replacePlaceholders(template, values) {
    if (!template || !values) return
    const message = template.replace(/{{(\d+)}}/g, (_, index) => `@#${values[parseInt(index) - 1] || ''}@#`)
    return message;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(field) => setFormValues(prev => ({ ...prev, ...field }))}
      initialValues={{
        ...templateToEdit,
        suggestedReplies: templateToEdit?.buttons
          ?.find(button => button.type === "QUICK_REPLY"),
        url: templateToEdit?.buttons?.find(button => button.type === "URL")?.url
      }}
    >
      <div>
          <div className='yp-workspace'>
            <Title level={4} type="secondary" style={{ textAlign: "center" }}>{t("edit-title")}</Title>
            <Row>
              <Col span={6} style={{ padding: "0px 15px" }}>
                <Form.Item
                  name="category"
                  label={t("label.category")}
                  rules={[{ required: true, message: t("rules.category-required") }]}
                  style={{ width: '100%' }}
                >
                  <Select id="edit-template-category-input" placeholder={t("placeholder.select-category")} disabled={templateToEdit?.status === "APPROVED"}>
                    <Option value="UTILITY">{t("options.utility")}</Option>
                    <Option value="MARKETING">{t("options.marketing")}</Option>
                    <Option value="AUTHENTICATION">{t("options.authentication")}</Option>
                  </Select>
                </Form.Item>
                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                  <Card className="category-card" title={t("options.utility")}>
                    <Text>{(t("card.utilityText"))}</Text>
                  </Card>
                  <Card className="category-card" title={t("options.marketing")}>
                    <Text>{(t("card.marketingText"))}</Text>
                  </Card>
                  <Card className="category-card" title={t("options.authentication")}>
                    <Text>{(t("card.authenticationText"))}</Text>
                  </Card>
                </div>
              </Col>
              <Col span={12} style={{ padding: "0px" }}>
                <Row style={{ marginBottom: "20px" }}>
                  <Col span={12} style={{ padding: "0px 10px 0px 0px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="name"
                        label={t("label.template-name")}
                        rules={[{ required: true, message: t("rules.template-name-required") }]}
                        style={{ width: '100%' }}
                      >
                        <Input id="edit-template-name-input" placeholder={t("placeholder.choose-template-name")} disabled />
                      </Form.Item>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Item
                        name="language"
                        label={t("label.language")}
                        rules={[{ required: true, message: t("rules.language-required") }]}
                        style={{ width: '100%' }}
                      >
                        <Select id="edit-template-language-input" placeholder={t("placeholder.select-language")} disabled>
                          <Option value="es">{t("options.spanish")}</Option>
                          <Option value="en">{t("options.english")}</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name="bodyExample"
                      label={
                        <>
                          {t("label.example-values")}
                          <HelperButton
                            id="create-template-example-values-tooltip"
                            message={t("tooltip.example-values")}
                            color={getGradientColor(purpleColor)}
                            placement='right'
                          />
                        </>
                      }
                    >
                      <Select
                        id="create-template-example-values-input"
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder={t("placeholder.type-example-values")}
                        options={wcList}
                        onChange={v => setwcList(v.map((_v) => ({ value: _v, label: _v })))}
                        allowClear
                        disabled={templateToEdit?.category === "AUTHENTICATION"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="headerText"
                      label={t("label.header")}
                    >
                      <Input id="edit-template-header-input" placeholder={t("placeholder.header")} disabled={templateToEdit?.category === "AUTHENTICATION"} />
                    </Form.Item>
                    <Form.Item
                      name="footerText"
                      label={t("label.footer")}
                    >
                      <Input id="edit-template-footer-input" placeholder={t("placeholder.footer")} disabled={templateToEdit?.category === "AUTHENTICATION"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item
                      name="bodyText"
                      label={
                        <>
                          {t("label.message")}
                          <HelperButton
                            id="create-template-message-body-tooltip"
                            message={t("tooltip.message")}
                            color={getGradientColor(purpleColor)}
                            placement='right'
                          />
                        </>
                      }
                      rules={[{ required: templateToEdit?.category !== "AUTHENTICATION", message: t("rules.message-required") }]}
                      style={{ width: '100%' }}
                      className='message-form-item'
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Col style={{ width: "100%" }}>
                          <MessageWildcard
                            style={{ width: "100%", background: (templateToEdit?.category === "AUTHENTICATION") ? "#F5F5F5" : "" }}
                            disabled={templateToEdit?.category === "AUTHENTICATION"}
                            contentEditable={true}
                            wildcardsFields={wcList.map(v => v.value)}
                            defaultMessage={{ message: messageBody, html: "" }}
                            defaultWildCards={wcList}
                            onChangeMessage={handleChangeMessage}
                            placeholder={t("placeholder.message")}
                          />
                        </Col>
                      </div>
                    </Form.Item>
                  </div>
                </Row>
                <label>{t("label.suggested-actions")}</label>
                <Row>
                  <Col span={12} className='template-suggested-replies-field'>
                    <div className='action-container'>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Form.Item
                          name="suggestedReplies"
                          label={t("label.suggested-replies")}
                          valuePropName='checked'
                        >
                          <Checkbox disabled={templateToEdit?.category === "AUTHENTICATION"} id="edit-template-suggested-replies-input" onChange={() => handleCheckboxChange('QUICK_REPLY')} />
                        </Form.Item>
                        <div className='group-options'>
                          <Checkbox.Group
                            disabled
                            options={[t("options.yes"), t("options.no")]}
                            onChange={handleQuickReplyChange}
                            value={buttons.filter(b => b.type === "QUICK_REPLY").map(b => b.text)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className='template-url-field'>
                    <div className='action-container'>
                      <Form.Item
                        name="url"
                        label={t("label.url")}
                        valuePropName='checked'
                      >
                        <Checkbox
                          id="edit-template-url-input"
                          onChange={() => handleCheckboxChange('URL')}
                          disabled={templateToEdit?.category === "AUTHENTICATION"}
                          checked={selectedActions.includes("URL")}
                        />
                      </Form.Item>
                      <div className='inputs'>
                        <Form.Item
                          name="url"
                          rules={[
                            {
                              type: 'url',
                              message: t("rules.valid-url"),
                            },
                            {
                              required: selectedActions.includes("URL"),
                              message: t("rules.url-required"),
                            },
                          ]}
                          style={{ width: "100%", marginBottom: "0px" }}
                        >
                          <Input
                            id="create-template-url-url-input"
                            style={{ width: "100%" }}
                            disabled={!selectedActions.includes("URL") || templateToEdit?.category === "AUTHENTICATION"}
                            onChange={(e) => handleURLChange(e, 'url')}
                            value={urlInputs.url}
                            placeholder={t("placeholder.url")}
                            type='url'
                          />
                        </Form.Item>
                        <Form.Item
                          name="urltext"
                          rules={[
                            {
                              required: selectedActions.includes("URL"),
                              message: t("rules.url-text-required"),
                            },
                          ]}
                          style={{ width: "100%", marginBottom: "0px" }}
                        >
                          <Input
                            id="create-template-url-text-input"
                            disabled={!selectedActions.includes("URL") || templateToEdit?.category === "AUTHENTICATION"}
                            onChange={(e) => handleURLChange(e, 'text')}
                            value={urlInputs.text}
                            placeholder={t("placeholder.url-text")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <div className="device-mockup">
                  <div className="device-frame">
                    <div className="notch"></div>
                    <div className="device-header">
                      <div className='contact'>
                        <div className='person-avatar' />
                        <Text strong>{t("preview.contact")}</Text>
                      </div>
                      <div className="header-icons">
                        <Icons.PhoneOutlined />
                      </div>
                    </div>
                    <div className="chat-preview">
                      <div className="message-card">
                        <Text id="edit-template-preview-header" strong>{formValues?.headerText}</Text>
                        <div className="chat-bubble">
                          <div className="inner-message-card">
                            <Text id="edit-template-preview-message-body">{templateToEdit?.category === "AUTHENTICATION" ? cleanMessage(replacePlaceholders(previewMessageBody, templateToEdit?.bodyExample)) : previewMessageBody || t("preview.message")}</Text>
                            <div className='message-footer'>
                              <div id="edit-template-preview-footer" className="footer-text">{formValues?.footerText}</div>
                            </div>
                          </div>
                          <div className="bubble-arrow"></div>
                        </div>
                      </div>
                      {selectedActions.includes("QUICK_REPLY") && (
                        <div className="button-group">
                          {buttons
                            .filter((button) => button.type === "QUICK_REPLY")
                            .map((button, index) => (
                              <div key={index} id={`edit-template-preview-button-${button.text}`} className="reply-button">
                                {button.text}
                              </div>
                            ))}
                        </div>
                      )}

                      {selectedActions.includes("URL") && (
                        <div className="url-group">
                          {buttons
                            .filter((button) => button.type === "URL")
                            .map((button, index) => (
                              <div key={index} className="url-button">
                                <a id="create-template-preview-url" href=' ' style={{ pointerEvents: "none" }} target="_blank" rel="noopener noreferrer">
                                  {button.text}
                                </a>
                                
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className="chat-input">
                      <input type="text" disabled placeholder={t("preview.input")} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles['step-buttons-container']}>
            <div className={styles['step-buttons']}>
              <Button id="edit-template-cancel-button" className="yp-button yp-button-optional" style={{ width: 150, marginRight: "20px" }} onClick={() => setIdToEdit(null)}>
                {t("actions-buttons.cancel")}
              </Button>
              <Button id="edit-template-save-button" className={`${styles['yp-button']} ${styles['yp-button-primary']}`} style={{ width: 150 }} type="primary" onClick={onFinish} disabled={templateToEdit?.category === "AUTHENTICATION"}>
                {t("actions-buttons.save-template")}
              </Button>
            </div>
          </div>
      </div>
    </Form>
  );
};

export default EditWhatsAppTemplate;
