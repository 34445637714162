import CardFilter from '../../CardFilter/CardFilter';
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CardHorizontal from "../../CardHorizontal/CardHorizontal";
import { notificationError, notificationOk } from "../../../utils/helpers";
import styles from "./accountmetrics.module.css";
import { Icons } from '../../../utils/icons';
import { faHandshakeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";
import { v4 as uuidv4 } from "uuid";
import accountApi from "../../../Api/AccountApi";

const AccountMetrics = ({ accountMetrics, clearAccountMetrics, getDataAccounts  }) => {
  
  const [t] = useTranslation("account");
  const [title, setTitle] = useState('Account Metrics');
  const [modalMessage, setModalMessage] = useState("");
  const [modalUsersVisible, setModalUsersVisible] = useState(false);
  const [userDisabled, setUserDisabled] = useState();
  const {  UpdateAccount } = accountApi();
  const [accountMetricsData, setAccountMetricsData] = useState([]);
  
  useEffect(() => {
    if(accountMetrics && accountMetrics.account && accountMetrics.principalUser) {
      const filteredUsersByAccount = accountMetrics.accountMetrics.allUsersByAccount.filter(user=> user.id !== accountMetrics.principalUser);
      setAccountMetricsData(filteredUsersByAccount);
      setTitle(`${t("top-menu.title-top-menu-metrics")} ${accountMetrics.account.name}`);
    } else {
      setAccountMetricsData([]); // Sets the state to an empty array if no data is available.
      setTitle('No Account Selected');
    }
  }, [accountMetrics, t]);

  const onDissasociateUser = user =>{
    let message = t("dissasociate-account.button-submit-confirm");
    let updatedAccountUsers = accountMetrics.account.accountUsers.filter(userId => userId !== user.id);

    const updatedAccount = {
      name: accountMetrics.account.name,
      description: accountMetrics.account.description,
      accountUsers: updatedAccountUsers
    };

    setModalMessage(message);
    setUserDisabled(updatedAccount);
    setModalUsersVisible(true);
  }

  const handleConfirmModal = () => {
    let user = userDisabled;
    let accountId = accountMetrics.account.accountId;

    UpdateAccount(user, accountId).then(()=>{
      setModalUsersVisible(false);
      clearAccountMetrics();
      getDataAccounts();
      setTimeout(() => {
        notificationOk(t('dissasociate-account.button-success'));
      }, 3000);
    }).catch(err => {
      setTimeout(() => {
        notificationError('dissasociate-account.button-error' + err)
      }, 3000); 
    })
  }

  const hasUsers = accountMetricsData && accountMetricsData.length > 0;
  return (    
    <CardFilter
      style={{borderLeft: 0}}
      title={title}
      body={
        <Fragment>
          <Row gutter={16} >
            <Col span={24}>
              <CardHorizontal>
                <div className="card">
                  <p className={styles['yp-title-user']}>Assigned Users</p>
                  {hasUsers ? (
                    <table className={styles['custom-table']}>
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Role</th>
                          <th>Dissasociate</th>
                        </tr>
                      </thead>
                      <tbody>
                        { accountMetricsData.map((user, index) => (
                          <tr key={user.id}>
                            <td>{user.displayName}</td>
                            <td>{user.role}</td>
                            <td className={styles['button-cell']}>
                              <button
                                className={styles['icon-button']}
                                onClick={()=> onDissasociateUser(user)}
                                key={user.id}
                                 id={`Button-to-dissociate-user-${index + 1}`}
                              >
                                <Icons.FontAwesomeIcon icon={faHandshakeSlash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className={styles['p-noData']} >No Data</p>
                  )}
                </div>
              </CardHorizontal>
            </Col>
          </Row>
          <ModalConfirmation
            open={modalUsersVisible}
            title={t("dissasociate-account.label-title")}
            onCancel={() => setModalUsersVisible(false)}
            onOk={handleConfirmModal}
            okBtnText={t("dissasociate-account.button-submit")}
          >
          <p>{modalMessage}</p>
          </ModalConfirmation>
        </Fragment>
      }
    />
  );
}

export default AccountMetrics;



