import { Menu, Spin, } from "antd";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon, Icons } from "../../utils/icons";


const MenuCustom = ({ data }) => {
  const [activeKeys, setActiveKeys] = useState({ itemKey: null, submenuKey: [] });
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga del menú
  const { t } = useTranslation("menu");

  const menuNameIconMapping = {
    "ChartSimple": Icons.ChartSimple,   
    "Building": Icons.Building,
    "Store": Icons.Store,
    "ClipboardList": Icons.ClipboardList,
    "FileChartLine": Icons.FileChartLine,
    "AddressBook": Icons.AddressBook,
    "FolderTree": Icons.FolderTree,
    "Users": Icons.Users,
    "UserPlus": Icons.UserPlus,
    "ListUl": Icons.ListUl,
    "File": Icons.File,
    "Message": Icons.Message,
    "Link": Icons.Link,
    "Voicemail": Icons.Voicemail,
    "List": Icons.List,
    "Copy": Icons.Copy,
    "Hammer": Icons.Hammer,
    "WhatsappSquare": Icons.WhatsappSquare,
    "CommentDots": Icons.CommentDots,
  };

  const findMenuItemByPath = (menuData, path) => {
    const traverseMenu = (items, parentKeys = []) => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const currentKey = parentKeys.concat(i);

        if (item.link === path) {
          return {
            itemKey: `menu-item-${currentKey.join('-')}`,
            submenuKey: parentKeys.map((key, index) => `menu-item-${parentKeys.slice(0, index + 1).join('-')}`)
          };
        }

        if (item.children) {
          const foundChild = traverseMenu(item.children, currentKey);
          if (foundChild) {
            return foundChild;
          }
        }
      }
      return null;
    };

    return traverseMenu(menuData);
  };

  const handleMenuSelect = ({ key, keyPath }) => {
    setActiveKeys({ itemKey: key, submenuKey: keyPath.slice(1).reverse() });
  };

  useEffect(() => {
    const path = location.pathname;
   
    const foundItem = findMenuItemByPath(data, path);
    if (foundItem) {
      const { itemKey, submenuKey } = foundItem;
      
      setActiveKeys({ itemKey, submenuKey });
    } else {
     
    }
  }, [location.pathname, data]);

  useEffect(() => {
    setLoading(true); // Inicia la carga del menú
    // Simula una carga de datos (aquí llamo el API o utilizo data directamente)
    setTimeout(() => {
      setLoading(false); // Finaliza la carga del menú
    }, 2000); // Simula una carga de 2 segundos
  }, []);


  const renderMenu = (item, parentIndex) => {
    const menuItemId = `menu-item-${parentIndex}`;    
    const iconName = menuNameIconMapping[item.icon];
    //obtiene el icono Fntawesome correspondiente
    const icon = iconName ? eval(iconName) : null;
    //Agrega clase basada de acuerdo al texto del menu
    const menuClass = item.menuName.toLowerCase().replace(/\s/g, '-');

    if (item.children && item.children.length > 0) {
      return {       
          key: menuItemId,
          label: (
            <>
             {icon && <FontAwesomeIcon icon={icon} />}
              <span>{t(item.menuName)}</span>
            </>
          ),            
          children: item.children.map((child, idx) => renderMenu(child, `${parentIndex}-${idx}`)),
          id: menuItemId, 
          className: menuClass, 
        };
    } else {
      return {
        key: menuItemId,
        label: (
          <>
            {icon && <FontAwesomeIcon icon={icon}/>}
            <Link to={item.link}>           
              {t(item.menuName)}
            </Link>
          </>
        ),
        id: menuItemId, 
        className: menuClass,       
      };
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Spin size="large" />        
      </div>
    );
  }

  // Maneja el caso cuando data está vacío
  if (!data || data.length === 0) {
    return <div style={{ textAlign: "center", margin: "5px" }}>
      <p>{t("data-menu.error-data-menu")}</p>
    </div>; // Mensaje para cuando no hay datos
  }

  // Convierte los datos del menú en un array de elementos
  const menuItems = data.map(renderMenu);

  return (
    <Menu
      mode="inline"
      selectedKeys={[activeKeys.itemKey]}
      defaultOpenKeys={activeKeys.submenuKey}
      onSelect={handleMenuSelect}
      items={menuItems} // Usa items en lugar de children
    />
  );
};

export default MenuCustom;
