import { Row, Col, Spin } from 'antd';
import propTypes from 'prop-types'
import { currencyFormat, numberFormat } from '../../../../utils/helpers';
import styles from "./CardSummary.module.css";
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag, faWallet } from '@fortawesome/pro-solid-svg-icons'
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next";
import { Icons } from "../../../../utils/icons";
import WhatsappApi from "../../../../Api/WhatsappApi";

const CardSummary = ({campaignId, data}) => {

  const [t] = useTranslation("whatsapp-campaign");

  const [ popoverVisible, setPopoverVisible ] = useState(false);
  const [ showSpinner, setShowSpinner ] = useState(false);
  const [ dataFile, setDataFile ] = useState(null);

  const { getDataFileByPrefix } = WhatsappApi();

  const handlerShowFileDetails = () => {

    if(popoverVisible) {
      setPopoverVisible(false);
    } else {
      if(dataFile) {
        setPopoverVisible(true);
      } else {
        setShowSpinner(true);
        getDataFileByPrefix(campaignId, data.prefix)
        .then(response => {
          setDataFile(response?.data || []);
          setPopoverVisible(true);
          setShowSpinner(false);
        })
        .catch(error => {
          console.error(error);
          setShowSpinner(false);
        });
      }
    }
  }

  return (
    <Fragment>
      <Spin spinning={showSpinner}>
        {popoverVisible && <div style={{ position: "absolute", backgroundColor: "#000000dd", color: "white", width: "100%", height: "100%", zIndex: 1, padding: "10px 10px 10px 32px", overflowY: "scroll" }}>
          <table>
            <thead>
              <tr style={{ fontSize: "14px" }}>
                <th style={{ minWidth: "130px" }}>{t("summary.card-label-destination-number")}</th>
                <th>{t("summary.card-label-message")}</th>
              </tr>
            </thead>
            <tbody>
              {
                dataFile && (dataFile.map((data, index) =>
                  <tr style={{ fontSize: "14px" }} key={index}>
                    <td style={{ minWidth: "130px" }}>{data.destinationNumber}</td>
                    <td>{data.messageContent}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>}
        <div style={{ border: popoverVisible ? "" : "1px solid #f2f2f2", borderWidth: popoverVisible ?  "1px 1px" : "1px", display: "flex", marginBottom: "10px", position: "relative" }}>
          {!popoverVisible ? <div style={{ width: "22px", padding: "2px", placeContent: "center", backgroundColor: "var(--main-color-purple)", zIndex: 1, cursor: "pointer" }} onClick={handlerShowFileDetails}>
              <Icons.ArrowRightOutlined style={{ color: "white", width: "10px" }} />
            </div> :
            <div style={{ width: "22px", padding: "2px", placeContent: "center", backgroundColor: "var(--main-color-orange)", zIndex: 1, cursor: "pointer" }} onClick={handlerShowFileDetails}>
              <Icons.ArrowLeftOutlined style={{ color: "white", width: "10px" }} />
            </div>
          }
          <div style={{ padding: "10px", width: "100%" }}>
            <Row>
              <Col span={14}>
                <div className={styles['falg']}>
                  <ReactCountryFlag
                    countryCode={data.countryCode}
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                    }}
                    title="US"
                  />
                  <div className={styles['falg']}>
                    <span className={styles['prefix']}>+{data.prefix}</span> {'  '}<p className={`yp-substitle ${styles['title-country']}`}>{data.countryName}</p>
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <p>{t("summary.card-label-total-message")}: </p>
                <p>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faHashtag} /> <span>{numberFormat(data.totalMessage, 0)}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={14}>
                <p className={styles['detail']}>{t("summary.card-label-rate-per-message")}: </p>
                <p>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> <span>{currencyFormat(data.unitRate, 5)}</span>
                </p>
              </Col>
              <Col span={10}>
                <p className={styles['detail']}>{t("summary.card-label-total-cost")}: </p>
                <p>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> <span className={styles['total']}>{currencyFormat(data.totalMessage * data.unitRate, 5)}</span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
}

CardSummary.propTypes = {
  campaignId: propTypes.string.isRequired,
  data: propTypes.object.isRequired
}

export default CardSummary;