import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import {b2cPolicies} from "../utils/policies"

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const { instance, accounts, inProgress } = useMsal();

  const [auth, setAuthContextState] = useState({
    userId: "",
    userName: "",
    accountId: "",
    userAccounts: [],
    isLogged: false,
    companyId: "",
    role: "",
    accessToken: null,
  });

  const signOut = () => {
    instance.logoutRedirect();
  };

  // Acquires and access token and then passes it to the API call
  const passTokenToApi = async (apiRequest) => {
    const request = {
      ...apiRequest,
      account: accounts[0],
    };

    try {
      // Intenta obtener el token de manera silenciosa
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Si no puede obtener el token silenciosamente, redirige al login
        instance.acquireTokenRedirect(request);
      } else {
        console.error("Error obteniendo el token:", error);
      }
    }
  };

  const addUserData = (data) => {
    if (data) {
      setAuthContextState({
        ...auth,
        userAccounts: data.accounts,
        companyId: data.companyId,
        role: data.role,
      });
    }
  };

 

  useEffect(() => {
    //console.log("inProgress", inProgress);
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) {
        const account = accounts[0];
        //console.log("Usuario ya registrado", account);
        setAuthContextState({
          ...auth,
          userId: account.localAccountId,
          userName: account.username,
          accountId: account.homeAccountId,
          isLogged: true,
        });
      } else {              
        instance
          .handleRedirectPromise()
          .then((data) => {
            if (!data)
              instance.loginRedirect();
            })
            .catch((error) => {
            if (
              error.errorMessage &&
              error.errorMessage.indexOf("AADB2C90118") > -1
            ) {
            console.error("Redirecting to Forgot Password flow...");
            instance.loginRedirect({
              //authority:"https://yellowpushtest.b2clogin.com/yellowpushtest.onmicrosoft.com/B2C_1_resetpassword",
              authority: b2cPolicies.authorities.forgotPassword.authority,
            });
            } else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
              instance.loginRedirect();
            }
          
            else{
              console.error("Error during login redirect:", error);
              instance.loginRedirect();
            }
          });
      }
    }
  }, [inProgress, accounts, instance]);  
    

  return (
    <AuthContext.Provider
      value={{ auth, signOut, passTokenToApi, addUserData }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
